const categories = {
    1: "吃喝玩樂",
    2: "飲酒享樂",
    3: "雲遊漫旅",
    4: "工商活動",
};

const categoriesOptions = [
    {
        label: "吃喝玩樂",
        value: 1,
    },
    {
        label: "飲酒享樂",
        value: 2,
    },
    {
        label: "雲遊漫旅",
        value: 3,
    },
    {
        label: "工商活動",
        value: 4,
    },
];

export { categories, categoriesOptions };
