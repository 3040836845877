<template>
    <div>
        <div class="p-5 bg-white rounded-lg">
            <div class="flex">
                <div class="flex-1">
                    <h3 class="my-5 text-xl">訂單編號</h3>
                    <p v-if="comment.dating === null">{{ "無訂單號" }}</p>
                    <p
                        v-else
                        class="text-red-500 underline cursor-pointer"
                        @click="
                            goOrder(
                                isProvider,
                                isProvider
                                    ? comment.dating.user_id
                                    : comment.dating.provider_id,
                                comment.dating.id
                            )
                        "
                    >
                        {{ comment.dating.order_id }}
                    </p>
                </div>
                <div class="flex-1">
                    <h3 class="my-5 text-xl">回應時間</h3>
                    <p>{{ comment.reviewed_at | formatDateTime }}</p>
                </div>
            </div>
            <div class="flex">
                <div class="flex-1">
                    <h3 class="my-5 text-xl">回饋人暱稱</h3>
                    <p v-if="comment.dating === null">
                        {{ comment.reviewer_real_name }}
                    </p>
                    <p
                        v-else
                        class="text-red-500 underline cursor-pointer"
                        @click="
                            goProfile(
                                isProvider,
                                isProvider
                                    ? comment.dating.user_id
                                    : comment.dating.provider_id
                            )
                        "
                    >
                        {{ comment.reviewer.name }}
                    </p>
                </div>
                <div class="flex-1">
                    <h3 class="my-5 text-xl">評分</h3>
                    <i
                        v-for="item in comment.score"
                        :key="item"
                        class="text-red-500 fas fa-star"
                    ></i>
                </div>
            </div>
            <div class="flex">
                <div class="flex-1">
                    <h3 class="my-5 text-xl">活動項目</h3>
                    <p>
                        {{
                            comment.dating === null
                                ? "無活動項目"
                                : categories[comment.dating.category_id]
                        }}
                    </p>
                </div>
                <div class="flex-1">
                    <h3 class="my-5 text-xl">狀態</h3>
                    <el-form ref="formRefDom" :model="form" :rules="rules">
                        <el-form-item prop="status">
                            <el-select v-model="form.status">
                                <el-option
                                    v-for="option in statusOptions"
                                    :key="option.value"
                                    :label="option.label"
                                    :value="option.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
        <div class="p-5 mt-5 bg-white rounded-lg">
            <h3 class="my-5 text-xl">社群回饋內容</h3>
            <p class="mx-5 my-5 break-all" style="white-space: break-spaces">
                {{ comment.comment }}
            </p>
            <div class="flex justify-center">
                <button
                    v-permission="['update']"
                    v-loading="loading"
                    class="myDisabled py-2 px-2 min-w-[150px] rounded-lg border border-black text-black hover:bg-black hover:text-white duration-500"
                    :disabled="loading"
                    @click="
                        router.push({
                            name: 'provider_update',
                            params: {
                                id: route.params.userId,
                                tab: 'EvaluationList',
                            },
                        })
                    "
                >
                    返回列表
                </button>
                <button
                    v-if="comment.dating === null"
                    v-permission="['update']"
                    v-loading="loading"
                    class="myDisabled py-2 px-2 ml-5 min-w-[150px] rounded-lg border border-red-500 text-red-500 hover:bg-red-500 hover:text-white duration-500"
                    :disabled="loading"
                    @click="showDialog = true"
                >
                    刪除資料
                </button>
                <button
                    v-permission="['update']"
                    v-loading="loading"
                    class="myDisabled py-2 px-2 ml-5 min-w-[150px] rounded-lg border border-red-500 bg-red-500 text-white hover:bg-red-700 hover:text-white duration-500"
                    :disabled="loading"
                    @click="updateCommentStatus()"
                >
                    儲存變更
                </button>
            </div>
        </div>
        <!-- 確認是否取消預訂彈窗 -->
        <my-dialog
            :showDialog="showDialog"
            :customClass="'top-1/4 z-[70]'"
            :customBlackBgClass="'z-[60]'"
            @onCloseDialog="closeDialog"
        >
            <div class="w-full p-5 text-sm bg-white rounded-lg md:p-10">
                <p>
                    確認刪除此<strong
                        v-if="!isEmpty(comment.comment)"
                        class="text-red-500"
                        >{{ comment.comment.substring(0, 10) }}</strong
                    >評論嗎？
                </p>
                <div class="flex justify-center mt-5">
                    <button
                        :disabled="loading"
                        class="border border-black px-3 py-0.5 rounded mr-5 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:text-white disabled:border-gray-300"
                        @click.prevent="closeDialog"
                    >
                        關閉
                    </button>
                    <button
                        v-loading="loading"
                        :disabled="loading"
                        class="bg-orange-600 px-3 py-0.5 rounded text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                        @click.prevent="deleteComment(comment.id)"
                    >
                        確認
                    </button>
                </div>
            </div>
        </my-dialog>
    </div>
</template>

<script >
import { ref, reactive, del, set, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import store from "@/store";
// 導入分類名稱
import { categories } from "@/config/homeSettingConfig";
// 導入自定義彈窗組件
import MyDialog from "@/components/Dialog.vue";
import { isEmpty } from "@/service/anyService";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const route = useRoute();
  const {
    proxy
  } = getCurrentInstance();
  const isProvider = computed(() => {
    if (comment.value.dating !== null) {
      return comment.value.reviewer.role !== 0;
    }
    return false;
  });
  const loading = ref(false);
  const formRefDom = ref(null);
  const form = ref({
    status: 0
  });
  const rules = ref({
    status: [{
      required: true,
      message: "請選擇啟用狀態",
      trigger: "blur"
    }]
  });
  // 顯示刪除彈窗判斷
  const showDialog = ref(false);
  function closeDialog() {
    showDialog.value = false;
  }
  // 評論資料
  const comment = ref({
    dating: {},
    comment: "comment",
    reviewer: {}
  });
  /**
   * 啟用狀態選擇
   */
  const statusOptions = ref([{
    label: "啟用",
    value: 0
  }, {
    label: "關閉",
    value: -1
  }]);
  /**
   * 表單發送
   * @example ref="form"
   */
  async function onSubmit() {
    try {
      // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
      await formRefDom.validate();
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "表單驗證失敗"
      });
    }
  }

  /**
   * 取得評論資料
   */
  async function getData() {
    loading.value = true;
    try {
      const {
        data
      } = await proxy.$api.GetCommentApi(route.params.id);
      comment.value = data;
      form.value.status = data.status;
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "取得資料失敗"
      });
    } finally {
      loading.value = false;
    }
  }

  /**
   * 刪除評論
   * @param { type String or Number(字串或數字) } id 評論 id
   */
  async function deleteComment(id) {
    loading.value = true;
    try {
      await proxy.$api.DeleteCommentApi(id);
      proxy.$message({
        type: "success",
        message: "刪除資料成功"
      });
      router.push({
        name: "provider_update",
        params: {
          id: route.params.userId,
          tab: "EvaluationList"
        }
      });
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "刪除評論失敗"
      });
    } finally {
      loading.value = false;
    }
  }

  /**
   * 開關評論
   */
  async function updateCommentStatus() {
    loading.value = true;
    try {
      await proxy.$api.UpdateCommentStatusApi(route.params.id, form.value.status);
      proxy.$message({
        type: "success",
        message: "更新評論狀態成功"
      });
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "更新評論狀態失敗"
      });
    } finally {
      loading.value = false;
    }
  }

  // 連結相關
  function goProfile(isProvider, id) {
    window.open(`/#/user_list/${isProvider ? "provider_update" : "member_update"}/${id}`);
  }
  // 連結相關
  function goOrder(isProvider, userId, orderId) {
    window.open(`/#/${userId}/OrderList/${isProvider ? "provider_update" : "member_update"}/order_detail/${orderId}`);
  }
  onActivated(() => {
    getData();
  });
  return {
    categories,
    isEmpty,
    router,
    route,
    isProvider,
    loading,
    formRefDom,
    form,
    rules,
    showDialog,
    closeDialog,
    comment,
    statusOptions,
    deleteComment,
    updateCommentStatus,
    goProfile,
    goOrder
  };
};
__sfc_main.components = Object.assign({
  MyDialog
}, __sfc_main.components);
export default __sfc_main;
</script>
